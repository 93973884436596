import {CatalogQuery} from './catalog.graphql.g';
import {FieldPolicy} from '@apollo/client/cache';
import {Reference} from '@apollo/client/utilities';

export type Products = CatalogQuery['catalog'];
export type Product = Products[number];

export const productCacheFieldPolicy: FieldPolicy<Reference> = {
  read: (_, {args, toReference}) => toReference({
    __typename: 'Product',
    id: args.productId,
  })
};
