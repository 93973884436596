import {EventEmitter, inject, Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {debounceTime} from 'rxjs/operators';
import {ConnectionAlertComponent} from './connection-alert/connection-alert.component';
import {LoggerService} from '../logger.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineNotifierService {
  private readonly logger = inject(LoggerService).create('Offline notifier');

  offline = new EventEmitter();

  private alert?: MatSnackBarRef<ConnectionAlertComponent>;

  constructor(private snackBar: MatSnackBar) {
    window.addEventListener('offline', () => this.offline.emit(true));
    window.addEventListener('online', () => this.offline.emit(false));
  }

  watchConnection() {
    this.offline
      .pipe(debounceTime(200))
      .subscribe(result => {
        if (result) {
          this.open();
          this.logger.logWarning('Offline');
        } else {
          this.dismiss();
          this.logger.logInfo('Online');
        }
      });
  }

  open() {
    this.alert = this.snackBar.openFromComponent(ConnectionAlertComponent, {
      panelClass: 'alert',
    });
  }

  dismiss() {
    this.alert?.dismiss();
  }
}
