import {Component, HostListener, inject, Inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {NavigationCancel, NavigationEnd, NavigationError, Router} from '@angular/router';
import {delay, filter, take} from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import {DOCUMENT} from '@angular/common';
import {LoggerService} from './logger.service';
import {AppService} from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private readonly logger = inject(LoggerService).create('App');

  constructor(private router: Router,
              title: Title,
              gtmService: GoogleTagManagerService,
              appService: AppService,
              @Inject(DOCUMENT) private document: Document) {

    this.logger.logInfo('App has been started', {appId: appService.appId});

    title.setTitle(environment.title);

    Sentry.setTags({appId: appService.appId});

    this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      take(1),
      delay(100),
    ).subscribe(() => {
      this.document.getElementById('main-loader').classList.add('hidden');
    });

    if (environment.production) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          gtmService.pushTag({
            event: 'page',
            pageName: item.url
          });
        }
      });
    }
  }

  @HostListener('document:visibilitychange')
  visibilitychange() {
    this.mute();
    setTimeout(() => this.mute(), 100);
    setTimeout(() => this.mute(), 500);
  }

  private mute() {
    this.document.querySelectorAll<HTMLMediaElement>('audio, video')
      .forEach(e => e.muted = this.document.visibilityState === 'hidden');
  }
}
