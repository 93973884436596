import {NgModule} from '@angular/core';
import {Apollo, ApolloModule} from 'apollo-angular';
import {from, InMemoryCache, split} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {getMainDefinition} from '@apollo/client/utilities';
import {environment} from '../../environments/environment';
import introspectionResult from '../../api.graphql.g';
import {AuthModule} from '../auth/auth.module';
import {RetryLink} from '@apollo/client/link/retry';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {sentryLink} from './sentry.link';
import {AuthLink} from '../auth/auth.link';
import {WsClient} from './ws-client.service';
import {productCacheFieldPolicy} from '../catalog/catalog';
import {AppService} from '../app.service';

@NgModule({
  imports: [
    ApolloModule,
    AuthModule,
  ]
})
export class GraphQLModule {
  constructor(apollo: Apollo,
              httpLink: HttpLink,
              authLink: AuthLink,
              wsClient: WsClient,
              appService: AppService) {

    const http = httpLink.create({
      uri: environment.apiUrl,
      headers: new HttpHeaders({
        'App-Id': appService.appId,
      }),
    });

    const retryLink = new RetryLink({
      delay: {
        max: 3000,
      },
      attempts: {
        max: 10,
        retryIf: error => error instanceof HttpErrorResponse && !error.ok
      }
    });

    const wsLink = new GraphQLWsLink(wsClient.create());

    apollo.create({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-only',
        }
      },
      link: from([
        sentryLink,
        split(
          ({query}) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
          },
          wsLink,
          from([
            retryLink,
            authLink.create(),
            http
          ]),
        ),
      ]),
      cache: new InMemoryCache({
        possibleTypes: introspectionResult.possibleTypes,
        typePolicies: {
          Query: {
            fields: {
              product: productCacheFieldPolicy,
            }
          },
        },
      }),
      connectToDevTools: !environment.production,
    });
  }
}
